<template>
    <div>
        <div @click="$router.push('/')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>
        <div class="text-center text-[#A1D9F7] text-[18px] mt-[10px] font-semibold">
            Проведені тренування
        </div>

        <div v-if="loading" class="bg-[#F2E9E4] fixed w-full h-full z-[140]">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[45%] left-[50%] ml-[-20px]"></div>
        </div>
        <div v-else class="mt-[20px]">
            <button v-for="training in completedTrainings" @click="selectTraining(training)" class="bg-[#A1D9F7] mb-2 rounded-[9px] text-[#F2E9E4] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                <div class="w-[20%]">{{ training.date.split('T')[0].split('-').reverse().join('.') }}</div>
                <div class="w-[40%] font-semibold truncate">{{ training.group_label }}</div>
                <div class="w-[20%]">{{ training.start_time }}</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#F2E9E4"/>
                </svg>
            </button>
        </div>

    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../stores/mainStore'
    import { getCompletedTrainingsByGroup } from '../services/apiRequests'

    export default {
        name: 'CompletedTrainingsView',

        setup() {
            const store = useMainStore()
            const router = useRouter()
            const completedTrainings = ref([])
            const loading = ref(true)

            onMounted(async () => {
                for (let i = 0; i < store.trainer_groups.length; i++) {
                    const response = await getCompletedTrainingsByGroup(store.trainer_groups[i].group_id)
                    completedTrainings.value.push(...response.data)
                }
                loading.value = false
            })

            const selectTraining = (training) => {
                store.currentCompletedTraining = training
                router.push('/completed-training-view')
            } 

            return {
                completedTrainings,
                selectTraining,
                loading
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>