<template>
    <div>
        <div class="mt-0">
            <div class="text-gray-emb flex justify-between mb-[7px] items-center">
                <div class="text-[9px] tracking-[0.2em]">//////////////</div>
                <div class="text-[12px] font-semibold uppercase">мої показники</div>
                <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            </div>
            <div>

                <div class="w-full flex justify-end px-[16px]">
                    <div>
                        <select v-model="currentMonthValue" class="bg-transparent border-[1px] border-gray-emb rounded-[4px]">
                            <option v-for="(month, id) in monthesOptions" :value="id + 1">{{ month }}</option>
                        </select>
                    </div>
                </div>
                
                <div v-if="dataLoaded" class="flex justify-evenly mt-[10px]">
                    <div v-for="group in tarriffGroupsStatistics"  class="flex flex-col gap-2 justify-center items-center">
                        <div>{{ group.group_name }}</div>
                        <PerCircle displayText="тренувань" :value="group.trainings_completed" :percent="countPercent(group.trainings_completed, sumTrainerTrainings)" progressBarColor="#BF637D" />
                        <!-- <PerCircle displayText="грн" :value="group.money_earned" :percent="countPercent(group.money_earned, sumTrainerMoney)" progressBarColor="#C3DDDC" /> -->
                        <PerCircle displayText="клієнтів" :value="group.clients_count" :percent="countPercent(group.clients_count, sumTrainerClients)" progressBarColor="#E6DDF0" />
                    </div>
                </div>
                <div v-else class="h-[248px]">
                    <div class="w-full flex justify-center items-center h-[200px]">
                        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent"></div>
                    </div>
                </div>

                <div class="w-full flex justify-end px-[16px] mt-[20px]">
                    <div>
                        <select v-model="currentYear" class="bg-transparent border-[1px] border-gray-emb rounded-[4px]">
                            <option v-for="year in yearsStatistic" :value="year">{{ year }}</option>
                        </select>
                    </div>
                </div>

                <div v-if="dataLoaded">
                    <div>
                        <div class="w-full text-end px-[22px] relative top-[20px] text-[9px] text-red-atten">тренувань</div>
                        <div class="w-full justify-center">
                            <MinLineChart :color="'#BF637D'" :data="completed_trainings_count" />
                        </div>
                    </div>
                    <!-- <div>
                        <div class="w-full text-end px-[22px] relative top-[20px] text-[9px] text-green-emb">дохід</div>
                        <div class="w-full justify-center">
                            <MinBarChart class="mt-[20px]" :data="earned_money" />
                        </div>
                    </div> -->
                    <div>
                        <div class="w-full text-end px-[22px] relative top-[20px] text-[9px] text-violet-emb">відвідуваність</div>
                        <div class="w-full justify-center">
                            <MinLineChart :color="'#525A60'" :data="clients_count" />
                        </div>
                    </div>
                </div>
                <div v-else class="h-[227px]">
                    <div class="w-full flex justify-center items-center h-[200px]">
                        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PerCircle from './PerCircle.vue'
    import MinBarChart from './MinBarChart'
    import MinLineChart from './MinLineChart'

    import { getTrainerCompletedTrainingsByMonthes, getTrainerClientsByMonthes, getTrainerEarnedMoneyByMonthes, getTrainerStatisticsByTarriffGroups } from '@/mobileApp/services/apiRequests'

    import { ref, onMounted, watch } from 'vue'
    import { useRoute } from 'vue-router'
    import { useMainStore } from '@/mobileApp/stores/mainStore'

    export default {
        name: "my-statistics-view",

        setup() {
            const completed_trainings_count = ref([])
            const earned_money = ref([])
            const clients_count = ref([])

            const route = useRoute()
            const store = useMainStore()
            const trainerId = store.trainer.trainer_id

            const dataLoaded = ref(false)

            const yearsStatistic = ref([])
            const currentYear = ref(new Date().getFullYear())
            const tarriffGroupsStatistics = ref([])

            const currentMonthValue = ref(new Date().getMonth() + 1)

            watch(currentYear, async (newValue) => {
                console.log(newValue)
                await updateChartStatistic(newValue)
            })

            watch(currentMonthValue, async (newValue) => {
                await updateDiagram(currentYear.value)
            })

            const generateMonthesOptions = () => {
                const monthes = ['Січ.', 'Лют.', 'Бер.', 'Кві.', 'Тра.', 'Чер.', 'Лип.', 'Сер.', 'Вер.', 'Жов.', 'Лис.', 'Гру.']
                const year = new Date().getFullYear()
                const currentMonth = new Date().getMonth()

                const monthesOptions = []

                for (let i = 0; i <= currentMonth; i++) {
                    monthesOptions.push(`${monthes[i]} ${year}`)
                }

                return monthesOptions
            }

            const monthesOptions = ref(generateMonthesOptions())

            const sumTrainerTrainings = ref(0)
            const sumTrainerMoney = ref(0)
            const sumTrainerClients = ref(0)

            const countPercent = (value, sum) => {
                return (value / sum) * 100
            }

            const updateDiagram = async (year) => {
                dataLoaded.value = false
                tarriffGroupsStatistics.value = (await getTrainerStatisticsByTarriffGroups(year, currentMonthValue.value, trainerId)).data

                sumTrainerClients.value = 0
                sumTrainerMoney.value = 0
                sumTrainerTrainings.value = 0

                sumTrainerClients.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.clients_count, 0)
                sumTrainerMoney.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.money_earned, 0)
                sumTrainerTrainings.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.trainings_completed, 0)

                dataLoaded.value = true
            }
            

            const updateChartStatistic = async (year) => {
                dataLoaded.value = false
                completed_trainings_count.value = (await getTrainerCompletedTrainingsByMonthes(year, trainerId)).data.map(item => item.trainings)
                earned_money.value = (await getTrainerEarnedMoneyByMonthes(year, trainerId)).data.map(item => item.money)
                clients_count.value = (await getTrainerClientsByMonthes(year, trainerId)).data.map(item => item.clients)

                dataLoaded.value = true
            }

            onMounted(async () => {
                completed_trainings_count.value = (await getTrainerCompletedTrainingsByMonthes(new Date().getFullYear(), trainerId)).data.map(item => item.trainings)
                earned_money.value = (await getTrainerEarnedMoneyByMonthes(new Date().getFullYear(), trainerId)).data.map(item => item.money)
                clients_count.value = (await getTrainerClientsByMonthes(new Date().getFullYear(), trainerId)).data.map(item => item.clients)

                console.log('earned_money', earned_money.value)

                tarriffGroupsStatistics.value = (await getTrainerStatisticsByTarriffGroups(currentYear.value, currentMonthValue.value, trainerId)).data

                sumTrainerClients.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.clients_count, 0)
                sumTrainerMoney.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.money_earned, 0)
                sumTrainerTrainings.value = tarriffGroupsStatistics.value.reduce((acc, item) => acc + item.trainings_completed, 0)

                for (let i = 0; i < 5; i++) {
                    yearsStatistic.value.push(currentYear.value - i)
                }

                dataLoaded.value = true
            })

            return {
                completed_trainings_count,
                earned_money,
                clients_count,
                dataLoaded,
                yearsStatistic,
                updateChartStatistic,
                currentYear,
                tarriffGroupsStatistics,
                sumTrainerClients,
                sumTrainerMoney,
                sumTrainerTrainings,
                countPercent,
                generateMonthesOptions,
                monthesOptions,
                currentMonthValue,
                updateDiagram
            }
        },

        components: {
            PerCircle,
            MinBarChart,
            MinLineChart
        }
    }
</script>

<style lang="scss" scoped>

    @font-face {
        font-family: 'Avenir Regular';
        src: url('@/mobileApp/assets/AvenirLTStd-Roman.otf') format('truetype');
    }

    .avenir-regular {
        font-family: 'Avenir Regular';
    }

</style>