<template>
    <div class="mt-[91px]">
        <div class="text-gray-emb flex justify-between mb-[7px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[12px] font-semibold uppercase">найближчі тренування</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>
        <div>
            <div :key="key" class="student-data-table overflow-y-hidden mt-1 mx-4 h-[268px] rounded-2xl py-1">
                <div class="mt-[5px] px-[5px] message-box overflow-y-auto">
                    <div v-for="training in planedTrainings" class="w-full flex h-[27px] items-center rounded-[4px] border-[1px] border-green-emb mb-[9px]">
                        <div class="h-full rounded-l-[4px] bg-green-emb w-[5px]"></div>
                        <div class="px-[14px] w-full flex text-gray-emb align-middle">
                            <div class="w-[7%] text-gray-emb align-middle text-[14px] ">
                                {{ getWeekDayShortNameByDate(training.date) }}.
                            </div>
                            <div class="w-[26%] text-gray-emb text-[14px] text-start font-semibold align-middle">
                                {{ training.date.split('T')[0].split('-').reverse().join('.') }}
                            </div>
                            <div class="w-[15%] text-gray-emb text-[14px] text-start align-middle">
                                {{ training.start_time }}
                            </div>
                            <div class="w-[32%] text-gray-emb text-[14px] truncate text-start align-middle">
                                {{ training.tariff_group_name }}
                            </div>
                            <div class="w-[20%] text-gray-emb font-bold text-[14px] truncate text-start align-middle">
                                {{ parseTrainingType(training.type) }}
                            </div>
                        </div>
                    </div>
                    
                    <div v-for="training in (7 - planedTrainings.length > 0 ? 7 - planedTrainings.length : 0)" class="w-full border-opacity-30 flex h-[27px] bg-white1 items-center rounded-[4px] border-[1px] border-gray-emb mb-[9.2px]">
                        <div class="h-full rounded-l-[4px] bg-gray-emb opacity-30 w-[5px]"></div>
                        <div class="px-[1vw] w-full flex text-gray-emb align-middle">
                            <div class="w-full text-[14px] opacity-60 text-center align-middle">
                                Відсутнє заплановане тренування
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from "vue"
    import { uuid } from "vue3-uuid"
    import { useMainStore } from "../../stores/mainStore" 
    import { getTrainerPlanedTrainings } from "../../services/apiRequests"

    export default {
        name: "trainings-block",

        setup() {
            const store = useMainStore()
            const key = ref(uuid.v4())

            const planedTrainings = ref([])

            onMounted(async () => {
                planedTrainings.value = (await getTrainerPlanedTrainings(store.trainer.trainer_id)).data
                console.log('planedTrainings.value', planedTrainings.value)
                key.value = uuid.v4()
            })

            const parseTrainingType = (type) => {
                switch (type) {
                    case 'pilates':
                        return 'пілатес'
                    case 'barre':
                        return 'барре'
                    case 'yoga':
                        return 'йога'
                    default:
                        return 'стречінг'
                }
            }

            const getWeekDayShortNameByDate = (date) => {
                const weekDays = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                const dateObj = new Date(date)
                return weekDays[dateObj.getDay()]
            }

            const formateDate = (date) => {
                const dateObj = new Date(date)

                const monthDay = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()
                const month = dateObj.getMonth() + 1 < 10 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1
                const year = dateObj.getFullYear()

                return `${monthDay}.${month}.${year}`
            }

            onMounted(() => {
                key.value = uuid.v4()
            })

            return {
                store,
                getWeekDayShortNameByDate,
                formateDate,
                key,
                planedTrainings,
                parseTrainingType
            }
        }
    }
</script>
