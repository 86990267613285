<template>
    <div class="w-full overflow-hidden h-[70px] flex items-center bg-rose-emb z-10 absolute">
        <div class="w-full flex overflow-hidden whitespace-nowrap mx-2 mb-1">
            <div class="flex justify-center w-full">
                <div class="text-[29px] text-gray-emb relative left-[16px] top-[2px] font-bold inline-block">{{ store.trainer.name + ' ' + store.trainer.surname }}</div>
            </div>
            <div @click="$router.push('/all-messages')" class="relative top-[15px] h-max mr-[20px]">
                <img src="@/mobileApp/assets/bell_icon.svg">
            </div>
        </div>
    </div>
</template>

<script>
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../../stores/mainStore'

    export default {
        name: "trainer-data",

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const selectGroup = (group) => {
                store.currentGroup = group
                router.push('/group-view')
            }

            return {
                store,
                selectGroup
            }
        }
    }
</script>
