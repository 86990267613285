<template>
  <div v-if="loadingSpinnerFlag" class="bg-[#FFF] z-50 fixed w-full h-full">
    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-red-atten border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
  </div>
  <div v-else class="contaier-fluid absolute left-[50%] translate-x-[-50%] pb-6 h-full">
    <router-view />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useMainStore } from './stores/mainStore'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'main-view',

    setup() {
        const store = useMainStore()
        const router = useRouter()
        const route = useRoute()

        const loadingSpinnerFlag = ref(true)

        const logout = () => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            router.push('/login')
            loadingSpinnerFlag.value = false
        }

        onMounted(async () => {
          if (localStorage.getItem('accessToken')) {
            router.push('/')
          }

          if (route.path == '/login') {
            loadingSpinnerFlag.value = false
            return
          }
          try {
            const resAuth = await store.getTrainerData()
            if (resAuth.status == 422) {
              console.log('error')
              logout()
            }
            await store.getStudentBalanceData()
            await store.getServiceBalanceData()
            await store.getTrainerGroups()
            await store.getAllTrainerPlanedTrainings()
            await store.getTrainerRealIncomes()
            loadingSpinnerFlag.value = false
          } catch (e) {
            console.log(e)
          }
        })

        return {
            logout,
            loadingSpinnerFlag
        }
    },
}

</script>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #FFF;
  }

  /* мобільні */
  @media (max-width: 767px) {
    .contaier-fluid {
      width: 100%;
    }
  }

  /* таблети */
  @media (min-width: 768px) {
    .contaier-fluid {
      width: 400px;
    }
  }

  /* ноути */
  @media (min-width: 992px) {
    .contaier-fluid {
      width: 400px;
    }
  }

  /* пекарні */
  @media (min-width: 1200px) {
    .contaier-fluid {
      width: 400px;
    }
  }
</style>