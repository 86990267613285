<template>
    <div>
        <div @click="$router.push('/')" class="flex text-gray-emb items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#525A60" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-gray-emb">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] uppercase border-gray-emb pb-[10px] w-[90%] mx-auto text-center font-semibold text-gray-emb mt-[10px]">
            Клієнти
        </div>

        <div v-if="clients.length" v-for="client in clients">
            <div class="flex text-[#4A4E69] relative top-[15px] items-center ml-[10px] mr-[16px] ">
                <div class="w-full pt-[3px] flex items-center">
                    <div class="w-[52.1px] h-[44px] mr-[10px] relative flex flex-col justify-end bottom-[5px] rounded-full overflow-hidden border-[2px] border-[#525A60] ">
                        <img v-if="client?.wallpaper_photo" class="w-full bg-white " :src="apiURL + 'static/' + client?.wallpaper_photo">
                        <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ client.name[0].toUpperCase() + client.surname[0].toUpperCase() }}</div>
                    </div>
                    <div class="w-full mt-[px]">
                        <div class="w-full border-opacity-20 ">
                            <div class="font-bold relative text-[16px] text-gray-emb bottom-[3px] w-[37%]">{{ client.name + ' ' + client.surname  }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="text-center mt-[20px] text-gray-emb text-[13px] font-light leading-tight">Клієнтів ще немає</div>
        </div>

    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useRoute } from 'vue-router'
    import { getTrainingById, getClientsListByTrainingId } from '../services/apiRequests'

    export default {
        name: 'AllClientsView',

        setup() {
            const clients = ref([])
            const route = useRoute()

            const trainingID = route.params.id
            const training = ref(null)

            const apiURL = process.env.VUE_APP_API_URL

            onMounted(async () => {
                training.value = (await getTrainingById(trainingID)).data
                console.log('training.value', training.value)

                clients.value = (await getClientsListByTrainingId(trainingID)).data
            })

            return {
                clients,
                training,
                apiURL
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>