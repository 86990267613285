import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './registerServiceWorker'

import mobileApp from './mobileApp/App.vue'
import mobileRouter from './mobileApp/router'

import desktopApp from './desktopApp/App.vue'
import desktopRouter from './desktopApp/router'

const pinia = createPinia()

// if (window.innerWidth < 968) {
const app = createApp(mobileApp)
app.use(mobileRouter).use(pinia).mount('#app')
// } else {
// const app = createApp(desktopApp)
// app.use(desktopRouter).use(pinia).mount('#app')
// }

