<template>
  <div class="chart flex justify-center w-full">
    <svg ref="svg" height="100" width="370"></svg>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

export default {
  name: 'MinLineChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: '#22223B',
    },
  },
  setup(props) {
    const svg = ref(null);
    let chartWidth, chartHeight, max;

    const updateChart = () => {
      if (!svg.value) return;

      const margin = 23;
      chartWidth = svg.value.clientWidth - margin * 1.3;
      chartHeight = svg.value.clientHeight;

      max = Math.max(...props.data) > 0 ? Math.max(...props.data) : 10;

      drawChart();
    };

    const drawChart = () => {
      const margin = 11;
      const svgElement = svg.value;

      while (svgElement.firstChild) {
        svgElement.removeChild(svgElement.firstChild);
      }

      const linePath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      let pathData = '';

      props.data.forEach((dataPoint, index) => {
        const x = margin + (index / (props.data.length - 1)) * chartWidth;
        const y = (chartHeight - (dataPoint / max) * chartHeight * 0.2) - 25;
        if (index == 0) {
          pathData += `M ${x}, ${y}`;
        } else {
          pathData += ` L ${x}, ${y}`;
        }
      });

      linePath.setAttribute('d', pathData);
      linePath.setAttribute('stroke', props.color);
      linePath.setAttribute('stroke-width', '4');
      linePath.setAttribute('fill', 'none');

      svgElement.appendChild(linePath);

      props.data.forEach((dataPoint, index) => {
        const x = margin + (index / (props.data.length - 1)) * chartWidth;
        const y = (chartHeight - (dataPoint / max) * chartHeight * 0.2) - 25;

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', x);
        text.setAttribute('y', y - 10);
        text.setAttribute('fill', props.color);
        text.setAttribute('font-size', '12px');
        text.setAttribute('font-weight', 'bold');
        text.setAttribute('text-anchor', 'middle');
        text.textContent = dataPoint.toLocaleString();

        svgElement.appendChild(text);
      });

      const months = [
        'Січ',
        'Лют',
        'Бер',
        'Кві',
        'Тра',
        'Чер',
        'Лип',
        'Сер',
        'Вер',
        'Жов',
        'Лис',
        'Гру',
      ];

      props.data.forEach((dataPoint, index) => {
        const x = margin + (index / (props.data.length - 1)) * chartWidth;

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', x);
        text.setAttribute('y', chartHeight - 5);
        text.setAttribute('fill', props.color);
        text.setAttribute('font-size', '12px');
        text.setAttribute('text-anchor', 'middle');
        text.textContent = months[index];

        svgElement.appendChild(text);
      });
    };

    onMounted(() => {
      updateChart();
    });

    watch(() => props.data, () => {
      updateChart();
    });

    return {
      svg,
    };
  },
};
</script>
