<template>
  <div class="chart-container w-max">
    <svg ref="svg" class="mx-auto" height="100" width="370"></svg>
  </div>
</template>

<script>
function formatNumber(num) {
  const absNum = Math.abs(num);

  if (absNum >= 1e9) {
    return (num / 1e9).toFixed(1) + 'G';
  } else if (absNum >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M';
  } else if (absNum >= 1e3) {
    return (num / 1e3).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
}

export default {
  name: 'BarChart',

  props: {
    data: {
      type: Array,
      required: true
    },
    barColor: {
      type: String,
      default: '#C3DDDC'
    },
    labels: {
      type: Array,
      required: true
    }
  },

  mounted() {
    this.drawChart();
  },

  methods: {
    drawChart() {
      const svg = this.$refs.svg;
      const height = parseInt(svg.getAttribute('height'));
      const width = parseInt(svg.getAttribute('width'));
      const max = Math.max(...this.data) > 0 ? Math.max(...this.data) : 21;
      const barWidth = width / (this.data.length * 1.4);
      const spacing = 2;
      const marginBottom = 25;
      const marginTop = 25;
      const barMaxHeight = height - (marginBottom + marginTop);

      this.data.forEach((bar, i) => {
        const buff = bar;

        const currentBarPercentFromFull = (100 * bar) / max;
        const currentBlockFullHeight = (barMaxHeight * currentBarPercentFromFull) / 100 > 0 ? (barMaxHeight * currentBarPercentFromFull) / 100 : 1;

        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        rect.setAttribute('x', (i * barWidth * 1.4) + spacing);
        rect.setAttribute('y', height - currentBlockFullHeight - marginBottom + 5);
        rect.setAttribute('width', barWidth);
        rect.setAttribute('height', currentBlockFullHeight);
        rect.setAttribute('fill', this.barColor);
        svg.appendChild(rect);

        let labelX = ((i * barWidth * 1.4) + spacing) + (barWidth / 2);
        let labelY = height - currentBlockFullHeight - marginBottom;
        let labelText = buff;

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', labelX);
        text.setAttribute('y', labelY);
        text.setAttribute('fill', '#C3DDDC');
        text.setAttribute('font-size', '12px');
        text.setAttribute('font-weight', 'bold');
        text.setAttribute('text-anchor', 'middle');
        text.textContent = formatNumber(buff);
        svg.appendChild(text);
      });

      const months = [
        'Січ',
        'Лют',
        'Бер',
        'Кві',
        'Тра',
        'Чер',
        'Лип',
        'Сер',
        'Вер',
        'Жов',
        'Лис',
        'Гру',
      ];

      months.forEach((label, i) => {
        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', ((i * barWidth * 1.4) + spacing) + (barWidth / 2));
        text.setAttribute('y', height - marginBottom + 20);
        text.setAttribute('fill', '#C3DDDC');
        text.setAttribute('font-size', '12px');
        text.setAttribute('text-anchor', 'middle');
        text.textContent = months[i];
        svg.appendChild(text);
      });
    }
  }
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
