<template>
    <div v-if="loadingSpinnerFlag" class="bg-[#F2E9E4] z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div v-else class="w-full pb-6">
        <Header />
        
        <div class="w-full flex justify-center">
            <div :class="{
                'w-[93%]' : screenWidth > 1500,
                'w-[100%] px-[20px]' : screenWidth < 1500,
            }">
                <div class="cashbox w-full">
                    <div class="text-white text-[18px] mb-[11px] font-bold leading-tight">
                        КАСА
                    </div>
                    <div class="flex justify-between">
                        <div class="flex">
                            <div class="w-[260px] mr-[34px] h-[100px] bg-zinc-300 relative bg-opacity-10 rounded-lg border border-slate-700 backdrop-blur-[10px]">
                                <div class="w-full text-center text-gray-400 text-[19px] font-normal tracking-wide mt-[10px]">всі джерела</div>
                                <div class="w-full text-center text-[#F9245A] text-[36px] font-extrabold tracking-wider">8 000,00</div>
                            </div>
                            <div class="w-[260px] h-[100px] mr-[34px] bg-zinc-300 relative bg-opacity-10 rounded-lg border border-slate-700 backdrop-blur-[10px]">
                                <div class="w-full text-center text-gray-400 text-[19px] font-normal tracking-wide mt-[10px]">учнівський баланс</div>
                                <div class="w-full text-center text-[#4EAE3B] text-[36px] font-extrabold tracking-wider">8 000,00</div>
                            </div>
                            <div class="w-[260px] h-[100px] bg-zinc-300 relative bg-opacity-10 rounded-lg border border-slate-700 backdrop-blur-[10px]">
                                <div class="w-full text-center text-gray-400 text-[19px] font-normal tracking-wide mt-[10px]">сервісний баланс</div>
                                <div class="w-full text-center text-[#A1D9F7] text-[36px] font-extrabold tracking-wider">8 000,00</div>
                            </div>
                        </div>
                        <div> 
                            <div class="cursor-pointer bg-[#A1D9F7] py-[10px] px-[10px] rounded-[6px]">
                                Створити повідомлення
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-[70px] flex justify-between">
                    <div class="">
                        <div class="mb-[30px] flex justify-between">
                            <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ОПЛАТИ (всі групи)</span></div>
                            <div>
                                <select class="rounded-[4px] outline-none">
                                    <option>2023</option>
                                    <option>2022</option>
                                    <option>2021</option>
                                    <option>2020</option>
                                    <option>2019</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <BarChart class="relative right-[30px] top-[10px]" :data="chartData.data" :labels="chartData.labels"/>
                        </div>
                    </div>
                    <div class="">
                        <div class="mb-[30px] flex justify-between">
                            <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ОПЛАТИ (по кожній групі)</span></div>
                            <div>
                                <select class="rounded-[4px] outline-none">
                                    <option>травень 2023</option>
                                    <option>червень 2023</option>
                                    <option>липень 2023</option>
                                    <option>серпень 2023</option>
                                </select>
                            </div>
                        </div>
                        <div class="overflow-y-auto h-[190px] px-[20px] relative">
                            <div v-for="i in 10" class="flex items-center w-full py-[10px]">
                                <div class="relative bottom-[6px] text-[#A1D9F7] mr-[20px]">U-2011</div>
                                <OneLine />
                            </div>
                            <!-- <div class="bg-gradient-to-b from-transparent to-[#F2E9E4] w-full h-[40%] absolute bottom-0 left-0"></div> -->
                        </div>
                    </div>
                </div>

                <div class="mt-[70px] flex justify-between">
                    <div>
                        <div class="mb-[30px] flex justify-between">
                                <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ПРОВЕДЕНО ТРЕНУВАНЬ</span></div>
                                <div>
                                    <select class="rounded-[4px] outline-none">
                                        <option>2023</option>
                                        <option>2022</option>
                                        <option>2021</option>
                                        <option>2020</option>
                                        <option>2019</option>
                                    </select>
                                </div>
                            </div>
                        <div>
                            <LineChart :data="chartData.data" :labels="chartData.labels"/>
                        </div>
                    </div>

                    <div class="">
                        <div class="mb-[30px] flex justify-between">
                            <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ВІДВІДУВАННЯ ТРЕНУВАНЬ</span></div>
                            <div>
                                <select class="rounded-[4px] outline-none">
                                    <option>травень 2023</option>
                                    <option>червень 2023</option>
                                    <option>липень 2023</option>
                                    <option>серпень 2023</option>
                                </select>
                            </div>
                        </div>
                        <div class="overflow-y-auto h-[190px] px-[20px] relative">
                            <div v-for="i in 10" class="flex items-center w-full py-[10px]">
                                <div class="text-[#A1D9F7] mr-[20px]">U-2011</div>
                                <PercentLine />
                            </div>
                            <!-- <div class="bg-gradient-to-b from-transparent to-[#F2E9E4] w-full h-[40%] absolute bottom-0 left-0"></div> -->
                        </div>
                    </div>
                </div>


                <div class="mt-[70px] flex justify-between w-full">
                    <div class="messages-block border-blue-400 w-[900px] h-[308px]">
                        <div class="text-[#fff] font-bold text-[15px] text-start">
                            ПОВІДОМЛЕННЯ
                        </div>
                        <div class="message-box relative mt-[5px] h-[270px] overflow-auto pr-[8px] border-blue-400">
                            <div v-for="i in 4" class="w-full flex h-[58px] rounded-[8px] bg-[#A1D9F7] bg-opacity-5 border-[1px] border-[#A5A3A3] border-opacity-20 mb-2">
                                <div class="h-full rounded-l-md bg-gray-400 w-[8px]"></div>
                                <div class="w-full relative h-full truncate text-[14px] text-[#A1D9F7] px-[10px] py-[5px]">
                                    <div class="absolute top-[50%] opacity-60 left-[50%] translate-x-[-50%] translate-y-[-50%]">Відсутні повідомлень</div>    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="trainings-block h-[293px] border-red-400">
                        <div class="text-[#fff] relative left-[4px] text-[15px] font-bold text-start">
                            НАСТУПНІ ТРЕНУВАННЯ
                        </div>
                        <div class="mt-[5px] h-[268px] overflow-auto w-[400px] px-[5px] message-box">
                            <div v-for="training in 8" class="w-full flex h-[24px] items-center rounded-[4px] border-[1px] border-[#4E5568] mb-[9.2px]">
                                <div class="h-full rounded-l-[4px] bg-gray-400 w-[5px]"></div>
                                <div class="px-[1vw] w-full flex text-[#A1D9F7] align-middle">
                                    <div class="w-full text-[14px] opacity-60 text-center align-middle">
                                        Відсутнє заплановане тренування
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'

    import Header from '../components/Header.vue'
    import BarChart from '../components/MainComponents/BarChart.vue'
    import OneLine from '../components/MainComponents/OneLine.vue'
    import LineChart from '../components/MainComponents/LineChart.vue'
    import PercentLine from '../components/MainComponents/PercentLine.vue'

    export default {
        name: 'main-view',

        setup() {
            const store = useMainStore()
            const router = useRouter()
            const screenWidth = ref(window.innerWidth)

            const loadingSpinnerFlag = ref(true)

            const logout = () => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                router.go()
            }

            onMounted(async () => {
                await store.getTrainerData()
                await store.getStudentBalanceData()
                await store.getServiceBalanceData()
                await store.getTrainerGroups()
                await store.getAllTrainerPlanedTrainings()
                await store.getTrainerRealIncomes()
                loadingSpinnerFlag.value = false

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            return {
                logout,
                loadingSpinnerFlag,
                screenWidth,
                chartData: {
                    data: [1, 2, 3, 4, 4000, 6, 7, 2000, 3200, 1100, 2100, 3900],
                    labels: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
                },
            }
        },

        components: {
            Header,
            BarChart,
            OneLine,
            LineChart,
            PercentLine
        }
    }
</script>
