<template>
  <div
    class="percircle"
  >
    <div class="text-[#525A60] flex flex-col items-center">
      <div class="text-base font-bold">{{ value }}</div>
      <div class="text-[9px] mt-[-5px]">{{ displayText }}</div>
    </div>

    <svg viewBox="0 0 120 120">
      <circle
        stroke-width="16"
        :stroke="progressBarColor + '33'"
        cx="60"
        cy="60"
        r="50"
        fill="none"
      />

      <circle
        cx="60"
        cy="60"
        r="50"
        fill="none"
        stroke-width="16"
        :id="circleId"
        :stroke="progressBarColor"
        :stroke-dashoffset="99"
        :stroke-dasharray="100"
        :pathLength="100"
      />
    </svg>
  </div>
</template>

<script>
  import { uuid } from 'vue3-uuid'
  import { ref, onMounted } from 'vue'

  export default {
    
    props: {
      progressBarColor: {
        type: String,
        default: '#307bbb'
      },
      displayText: {
        type: String,
        default: 'test'
      },
      percent: {
        type: Number,
        default: 0
      },
      value: {
        type: Number,
        default: 0
      }
    },

    setup(props) {      
      const circleId = uuid.v4()

      onMounted(() => {
        const circle = document.getElementById(circleId)
        const offset = 100 - props.percent
        circle.style.strokeDashoffset = offset
      })

      return {
        circleId
      }
    }

  }
</script>

<style lang="scss" scoped>
.percircle {
  position: relative;
  font-size: 120px;
  width: 104px;
  height: 104px;
  margin: 0 auto;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    height: 1em;
    display: block;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    font-size: 0.15em;
    color: rgba(52, 59, 62, 0.5);
  }

  svg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
  }
}
</style>
